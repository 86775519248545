<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col flex-grow">
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <div class="bg-white overflow-hidden">
        <div class="px-4 py-5">
          <img
            class="mx-auto"
            src="@/assets/homeBanner.jpg"
            alt="A banner image with a photo of Jonathan Powell on the left, the words 'Arete Publishing Ltd' and a mountain range in the background."
          />
        </div>
      </div>
    </section>
    <section>
      <!-- FILTER -->
    </section>
    <div v-if="isLoggedIn" class="admin-controls mt-12 pt-8 flex justify-end">
      <base-button
        type="button"
        class="inline-flex items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        @click="loadPosts(true)"
      >
        <RefreshIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Reload posts
      </base-button>
      <base-button link to="/blog/new" class="ml-3 mr-4 lg:mr-12">
        <!--<button type="button" class="ml-3 lg:mr-12 inline-flex items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
					<PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					New Post
				</button>-->
        <PencilAltIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        New Post
      </base-button>
    </div>
    <section
      class="flex-grow relative max-w-7xl mx-auto bg-white mt-16 pt-8 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div class="md:grid grid-cols-6 grid-flow-col-dense gap-12 mb-20">
        <div class="col-span-6 md:col-start-4 md:col-span-3 text-gray mt-3 mb-12">
          <h2 class="tracking-tight font-extrabold text-gray-900 sm:text-4xl">Welcome</h2>
          <div class="prose mt-6">
            <p>
              Welcome to Aret&eacute; Publishing.
            </p>
            <p>
              The purpose of this site is to help you become the person you were born to be.
            </p>
            <p>
              Whether you've landed here because you just want a little nudge in the right direction or you've hit rock bottom there's an article here for you.
            </p>
            <p>
              But before you dive in here's a quick video introduction from our editor.
            </p>
          </div>
        </div>
        <div class="col-span-6 md:col-start-1 md:col-span-3 flex">
          <div class="relative w-full pb-[56.25%] mb-8 self-center">
            <iframe class="absolute top-0 left-0 w-full h-full" src="https://www.youtube-nocookie.com/embed/5E_KM_WZy2Y?start=11" title="A welcome message from Jonathan" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="md:grid grid-cols-6 gap-12 mb-10">
        <div class="col-span-6 md:col-start-2 md:col-span-2 text-gray mt-3 mb-12">
          <h2 class="tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            The Aret&eacute; newsletter
          </h2>
          <div class="prose mt-6">
            <p>
              Every month we send out exclusive new articles to our subscribers to help them on their journey to becoming who they were born to be.
            </p>
            <p>
              Join us today and we will send you a FREE 'Welcome' gift from our online shop!
            </p>
            <p>
              Take back control and discover how you can make the very best of your life and live life to its full potential.
            </p>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-2">
          <NewsLetter />
        </div>
      </div>
    </section>
    <section
      class="flex-grow relative bg-gray-50 mt-4 pt-8 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h2
            class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
          >
            From the blog
          </h2>
        </div>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-else-if="hasPosts"
          class="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12"
        >
          <blog-item
            v-for="post in filteredPosts"
            :key="post.id"
            :id="post.id"
            :title="post.title"
            :slug="post.slug"
            :description="post.description"
            :date-posted="post.datePosted"
            :image-url="post.imageUrl"
            :image-alt="post.imageAlt"
            :reading-time="post.readingTime"
            :author="post.author"
            :category="post.categoryName"
          ></blog-item>
        </div>
        <h3 v-else>No posts found.</h3>
      </div>
    </section>
  </div>
</template>

<script>
import BlogItem from '../../components/blog/BlogItem.vue'
import { RefreshIcon, PencilAltIcon } from '@heroicons/vue/solid'
import NewsLetter from '@/components/newsletter/NewsletterForm'

export default {
  components: {
    RefreshIcon,
    PencilAltIcon,
    BlogItem,
    NewsLetter
  },
  data() {
    return {
      isLoading: false,
      error: null,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated
    },
    filteredPosts() {
      return this.$store.getters['blog/posts']
    },
    hasPosts() {
      return !this.isLoading && this.$store.getters['blog/hasPosts']
    },
  },
  created() {
    this.loadPosts()
  },
  methods: {
    async loadPosts(refresh = false) {
      this.isLoading = true
      try {
        await this.$store.dispatch('blog/loadPosts', {
          forceRefresh: refresh,
        })
      } catch (error) {
        this.error = error.message || 'Something went wrong!'
      }
      this.isLoading = false
    },
    handleError() {
      this.error = null
    },
  },
}
</script>
