<template>
  <div class="overflow-hidden rss-feed-item">
    <p class="text-sm text-gray-500">
      <time :datetime="dateReadable">{{ dateReadable }}</time>
    </p>

    <router-link :to="postLink" class="mt-2 block">
      <p class="text-xl font-semibold text-gray-900">
        {{ title }}
      </p>

      <p class="mt-3 text-base text-gray-500">
        {{ description }}
      </p>
    </router-link>

    <div class="mt-3">
      <router-link
        :to="postLink"
        class="text-base font-semibold text-indigo-600 hover:text-indigo-500"
      >
        Read full story
      </router-link>
      <!-- <div class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1">
        <p v-if="category" class="text-sm font-medium text-indigo-600">
          {{ category }}
        </p>

        <router-link :to="postLink" class="block mt-2">
          <p class="text-xl font-semibold text-gray-900">
            {{ title }}
          </p>
          <p class="mt-3 text-base text-gray-500">
            {{ description }}
          </p>
        </router-link>
      </div>
      <div class="mt-6 flex items-center">
        <div class="ml-3">
          <p class="text-sm font-medium text-gray-900">By {{ author }}</p>
          <div class="flex space-x-1 text-sm text-gray-500">
            <time :datePosted="datePosted">
              {{ dateReadable }}
            </time>
            <span aria-hidden="true"> &middot; </span>
            <span> {{ readingTime }} read </span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: [String, Number],
    title: String,
    slug: String,
    categoryId: String,
    description: String,
    datePosted: String,
    imageUrl: String,
    imageAlt: String,
    readingTime: String,
    author: String,
    category: String,
  },
  computed: {
    dateReadable() {
      const date = new Date(Date.parse(this.datePosted))
      const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      return dateTimeFormat.format(date)
    },
    postLink() {
      const routePath = this.$route.path.endsWith('/')
        ? this.$route.path.slice(0, -1)
        : this.$route.path
      return routePath + '/' + this.slug
    },
  },
}
</script>
